/// @group breakpoints
$breakpoint-mobile: 37.5rem;
/// @group breakpoints
$breakpoint-tablet: 57.5rem;
/// @group breakpoints
$breakpoint-desktop: 75rem;
/// @group breakpoints
$breakpoint-query-above-mobile: "(min-width: 37.55rem)";
/// @group breakpoints
$breakpoint-query-above-tablet: "(min-width: 57.55rem)";
/// @group breakpoints
$breakpoint-query-above-desktop: "(min-width: 75rem)";
/// @group breakpoints
$breakpoint-query-mobile: "(max-width: 37.5rem)";
/// @group breakpoints
$breakpoint-query-tablet: "(max-width: 57.5rem)";
/// @group breakpoints
$breakpoint-query-tablet-only: "(min-width: 37.55rem) and (max-width: 57.5rem)";

////
/// @group breakpoints
////

@mixin breakpoint-mobile {
  @media #{$breakpoint-query-mobile} {
    @content;
  }
}

@mixin breakpoint-tablet {
  @media #{$breakpoint-query-tablet} {
    @content;
  }
}

@mixin breakpoint-tablet-only {
  @media #{$breakpoint-query-tablet-only} {
    @content;
  }
}

@mixin breakpoint-above-mobile {
  @media #{$breakpoint-query-above-mobile} {
    @content;
  }
}

@mixin breakpoint-above-tablet {
  @media #{$breakpoint-query-above-tablet} {
    @content;
  }
}

@mixin breakpoint-above-desktop {
  @media #{$breakpoint-query-above-desktop} {
    @content;
  }
}
