@import "../../styles/breakpoints.scss";

.Container {
    width: 100%;
    display: block;
    position: fixed;
    top: 0;

    @include breakpoint-tablet {
        display: none;
    }
}

.Desktop {
    display: none;
    @include breakpoint-above-desktop {
        display: block;
    }
}

.Left {
    width: 500px;
    position: absolute;
    left: -320px;
    top: 0;
    border-radius: 250px;
    box-shadow: 0px 0px 7px 3px #dedede;

    @include breakpoint-tablet {
        left: -300px;
    }
}

.Right {
    width: 500px;
    position: absolute;
    right: -320px;
    top: 150px;
    border-radius: 250px;
    box-shadow: 0px 0px 7px 3px #dedede;

    @include breakpoint-tablet {
        right: -300px;
    }
}
