@import "../../styles/breakpoints.scss";
@import "../../styles/theme.scss";

.Header {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;

    .Container {
        display: flex;
        flex-direction: column;
        min-width: 0;
        padding-right: 30px;
    }

    &.Detail {
        margin-bottom: 16px;
        padding-bottom: 16px;

        &::after {
            content: "";
            width: calc(100% + 32px);
            height: 1px;
            background: rgba(0, 0, 0, 0.05);
            position: absolute;
            left: -16px;
            bottom: 0;
        }
    }

    &.WithButtons {
        flex-direction: column;
        align-items: flex-start;
    }
}

.TitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .Icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 6px;

        img {
            margin: 0 5px;
        }
    }
}

.Title {
    font-family: $main-font;
    color: $secondary-color;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: static;
    font-weight: 600;
    margin: 0;
}

.Subtitle {
    color: $primary-color;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    margin: 0;

    &.Link {
        &:hover {
            text-decoration: underline;
        }
    }
}

.TextIcon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 10px;
    img {
        margin-right: 10px;
    }
    p {
        margin-top: 4px;
    }
}

.Buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.Button {
    border-radius: 3px;
    min-width: 90px;
    min-height: 34px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 15px;
    box-sizing: border-box;
    cursor: pointer;

    &:first-child {
        margin-right: 10px;
    }
    &:last-child {
        margin-left: 10px;
    }
    &:only-child {
        margin: 0px;
    }

    @include breakpoint-mobile {
        flex-grow: 1;
    }

    img + p {
        margin-left: 10px;
    }

    &.Primary {
        background: #00cc83;
        border: 2px solid #00cc83;

        &:hover {
            background: #00dc8d;
            border: 2px solid #00dc8d;
        }
    }

    &.Secondary {
        background: #0069e3;
        border: 2px solid #0069e3;

        &:hover {
            background: #0075ff;
            border: 2px solid #0075ff;
        }
    }

    p {
        font-family: $main-font;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.1rem;
        color: white;
        text-align: center;
        align-self: center;
    }
}

.Logo {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    position: relative;
    background: #fff;
    border: 2px solid #e6e9ed;
    margin-right: 20px;

    img {
        object-fit: cover;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 2px;
    }

    &.Squared {
        border: none;

        img {
            border-radius: 4px;
        }

        &.Speed::after {
            content: "";
            display: block;
            position: absolute;
            background: #ffffff url("../../resources/ic-speed.svg") no-repeat;
            background-position: 50%;
            width: 16px;
            height: 16px;
            padding: 5px;
            border-radius: 50%;
            bottom: -8px;
            right: -8px;
        }

        &.Fee::after {
            content: "";
            display: block;
            position: absolute;
            background: #ffffff url("../../resources/ic-fee.svg") no-repeat;
            background-position: 50%;
            width: 16px;
            height: 16px;
            padding: 5px;
            border-radius: 50%;
            bottom: -8px;
            right: -8px;
        }
    }
}

.Body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.Labels {
    width: 100%;
}

.Label {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    .Highlight {
        width: 80px;
        height: auto;
        background: #f3f5f6;
        border-radius: 2px;
        padding: 5px;
        font-family: $secondary-font;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: #000000;
        align-self: center;
    }

    p {
        font-family: $secondary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        align-self: center;
        margin: 0;
        padding-left: 20px;
    }
}

.Box {
    background: #e6faf3;
    border: 1px dashed #00cc83;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px;
    height: auto;
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    position: relative;

    &::after {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        top: -10px;
        right: 20px;
        background: url("../../resources/ic-scissors.svg");
    }

    p {
        font-family: $secondary-font;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #00b373;
        margin: 0;
    }
}
