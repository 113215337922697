@import "../../styles/theme.scss";

.Container {
    display: flex;
    flex-direction: column;
}

.TextsContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 15px 17px;
    background: transparent;
    text-align: center;

    p {
        margin: 0;
        font-family: $secondary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $background-color-dark;
        margin-bottom: 5px;

        &.Highlight {
            color: $primary-color;
            font-family: $secondary-font;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
        }
    }
}

.Image {
    margin-top: 5px;
    margin-bottom: 20px;
}