@import "../../styles/theme.scss";

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.Claim {
    color: $primary-color;
    font-size: 24px;
    text-align: center;
    font-family: $secondary-font;
    font-weight: 700;
    position: relative;
}

.MainText {
    font-family: $secondary-font;
    color: $background-color-dark;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.6em;
    text-align: center;
    padding: 0px 20px;
}

h1.MainText {
    margin: 0;
}

h2.MainText {
    margin: 0;
    margin-bottom: 40px;
}

.Logos {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;

    li {
        margin: 10px;
        padding: 0;
    }

    li img {
        width: 54px;
    }
}

.Supported {
    margin-top: 8em;
    font-family: $secondary-font;
    color: $background-color-dark;
    font-size: 0.8em;
    text-align: center;
}

.Lanzadera {
    margin-top: 0.6em;
    width: 230px;
}