@import "../../styles/theme.scss";
@import "../../styles/breakpoints.scss";

.Container {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    z-index: 2;
    justify-content: center;

    input {
        width: 100%;
        border-radius: 6px;
        padding: 16px 16px 16px 55px;
        text-align: left;
        border: 1px solid white;
        box-sizing: border-box;
        box-shadow: none;
        transition: border 0.3s ease-in-out;
        font-family: $main-font;
        font-size: 14px;

        @include breakpoint-tablet {
            padding: 16px 16px 16px 45px;
        }

        &:hover {
            border: 1px solid $background-color-medium;
        }
        &::placeholder {
            font-family: $main-font;
            font-size: 14px;
            color: #5a6f8b;
            z-index: 2;
        }
        &:focus {
            outline: none;
            border-width: 2px;
            border-color: $primary-color;;
        }
    }

    input[type="text"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

.Search {
    position: absolute;
    left: 20px;

    @include breakpoint-tablet {
        left: 15px;
    }
}

.Button {
    position: absolute;
    width: 30px;
    height: 30px;
    background: none;
    right: 5px;
    border: 0;
    padding: 0;
}

.Clear {
    width: 20px;
    height: 20px;
    padding: 5px;
}
