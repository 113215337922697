@import "../../styles/breakpoints.scss";

.Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    min-height: 100vh;
}

.Sidebar {
    width: 35%;
    padding: 30px;
    margin-right: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #ffffff;
    align-items: flex-end;
    box-shadow: 0px -5px 10px rgba(210, 219, 231, 0.5);

    @include breakpoint-tablet {
        display: none;
    }
}

.Hidden {
    display: none;
}
