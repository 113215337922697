@import "../../styles/breakpoints.scss";
@import "../../styles/theme.scss";

.Content {
    max-width: 600px;
    p {
        text-align: center;
        color: #ffffff;
        font-family: $secondary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        text-decoration: none;
    }
}

.Buttons {
    button {
        font-family: $secondary-font;
    }
}
