@import "../../styles/theme.scss";

.Header {
    padding: 16px;
    background: white;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 6px;
    text-align: left;
    border: 1px solid white;
    box-sizing: border-box;
    transition: border 0.3s ease-in-out;
    outline-color: $primary-color;
    img {
        margin-right: 10px;
    }
    p {
        font-family: $main-font;
        font-size: 14px;
        text-align: center;
        color: #5a6f8b;
        margin: 0;
        b {
            font-weight: 600;
        }
    }
    &:hover {
        border: 1px solid $background-color-medium;
    }
}
