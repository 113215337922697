@import './styles/theme.scss';

body {
  background: $background-color;
}

.results-body {
  background: $main-background-color;
}

.embedded-body {
  background: none transparent;
}

body,
html {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
