@import "../../styles/breakpoints.scss";

.Container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;

    @include breakpoint-tablet {
        width: 100%;
        align-items: center;
    }
}

.ShowMore {
    margin-top: 10px;
    max-width: 572px;

    @include breakpoint-tablet {
        width: 100%;
    }
}

.Error {
    padding: 20px;
    margin-top: 60px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
}