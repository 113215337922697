@import "../../styles/theme.scss";

.Form {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;

    input {
        font-family: $main-font;
        font-size: 16px;
        margin: 0 auto;
        border: $stroke;
        border-radius: 6px;
        width: 100%;
        margin: 0 auto;
        text-align: left;
        padding: 15px;
        box-sizing: border-box;
        box-shadow: none;
        margin: 20px auto;

        &:focus {
            outline: none;
            border-width: 2px;
            border-color: $primary-color;;
        }
    }

    input[type="search"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    input[type="search"]::-webkit-search-cancel-button {
        height: 20px;
        width: 20px;
        display: block;
        background-image: url("../../resources/ic-close.png");
        /* setup all the background tweaks for our custom icon */
        background-repeat: no-repeat;

        /* icon size */
        background-size: 20px;
    }
}

.Text {
    position: relative;
    width: 100%;

    a {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0;
        padding: 14px;
        top: 50%;
        transform: translateY(-50%);

        img {
            width: 100%;
            height: auto;
        }
    }
}

.Error {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #fffae5;
    padding: 15px 17px;
    margin-bottom: 20px;
    p {
        margin: 0;
        font-family: $secondary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $background-color-dark;
        margin-bottom: 5px;
    }
    a {
        color: $primary-color;
        font-family: $secondary-font;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        text-decoration-line: underline;
    }
}
