@import "../../styles/theme.scss";

// Animations
@keyframes gradient-button {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: -100% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}

.Button {
  font-family: $main-font;
  font-size: 1em;
  display: block;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  text-align: left;
  padding: 15px;
  background: $primary-color;
  box-shadow: 0 5px 10px rgba(210, 219, 231, 0.5);
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;

  &.Secondary {
    text-transform: unset;
    background: $secondary-button-color;
  }

  &.Disabled {
    background: #f0f2f6;
    color: #bac3d0;
    box-shadow: none;
    cursor: not-allowed;
  }
  &.Loading {
    background: linear-gradient(
      135deg,
      $primary-color 10%,
      $primary-color-light 10%,
      $primary-color-light 15%,
      $primary-color 15%,
      $primary-color 25%,
      $primary-color-light 25%,
      $primary-color-light 30%,
      $primary-color 30%,
      $primary-color 40%,
      $primary-color-light 40%,
      $primary-color-light 45%,
      $primary-color 45%,
      $primary-color 55%,
      $primary-color-light 55%,
      $primary-color-light 60%,
      $primary-color 60%,
      $primary-color 70%,
      $primary-color-light 70%,
      $primary-color-light 75%,
      $primary-color 75%,
      $primary-color 85%,
      $primary-color-light 85%,
      $primary-color-light 90%,
      $primary-color 90%
    );
    background-size: 200%;
    animation: gradient-button 1.5s linear infinite;
  }
}

.Link {
  text-decoration: none;
}