@import "../../styles/theme.scss";


.H1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #929292;
}

.H2 {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #373737;
}

.H3 {
    font-family: $main-font;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin: 10px auto;
}

.light {
    color: $background-color;
}

.dark {
    color: $primary-color;
}