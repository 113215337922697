@import "../../styles/theme.scss";
@import "../../styles/breakpoints.scss";

.Navbar {
    position: sticky;
    top: 0;
    width: 100%;
    height: 60px;
    background: $primary-color;
    z-index: 5;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.OnlyTablet {
    @include breakpoint-above-tablet {
        display: none;
    }
}

.Container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: border-box;
    z-index: 5;
    height: 60px;
    width: 100%;
    box-sizing: border-box;
}

.Logo {
    width: 120px;
}

.Btn {
    padding: 15px;
    border-radius: 3px;
    height: auto;
    position: relative;
    min-height: auto;
    min-width: auto;
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
}

.Back {
    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        height: 15px;
        margin-top: 2px;
        width: 9px;
        margin-right: 100px;
        background: url("../../resources/ic-back-mobile.svg") no-repeat;
    }
}

.Title {
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
}
