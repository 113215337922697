@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// colors

$primary-color: #FF101E;
$primary-color-light: #FF2D39;
$primary-color-opacity: rgba(255, 70, 79, 0.19);
$secondary-color: #000;
$secondary-button-color: #00CC83;
$main-background-color: #F0F2F6;
$main-text-color: #373737;
$dark-text-color: #A7A8A9;
$dark-color: #232528;
$secondary-text-color: #929292;
$background-color: #ffffff;
$background-color-light: #F0F2F6;
$background-color-medium: #D7E0EC;
$background-color-dark: #353e4a;

// styles

$stroke: 1px solid #8393A4;
$big-shadow: 0px 5px 10px rgba(210, 219, 231, 0.5);

// fonts

$main-font: 'Montserrat',
sans-serif;
$secondary-font: 'Open Sans',
sans-serif;
