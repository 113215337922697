@import "../../styles/breakpoints.scss";
@import "../../styles/theme.scss";

.Container {
    width: 250px;

    @include breakpoint-tablet {
        width: 100%;
    }
}

.StickyContainer {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.Section {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;

    :last-child {
        margin-bottom: 0;
    }
}

.Logo {
    align-self: center;
    margin-bottom: 40px;
    width: 150px;
    height: auto;
}

.Row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 14px;

    img {
        margin-right: 5px;
    }
}

.ItemText {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: auto;
    display: flex;
    flex-grow: 2;
    align-items: center;
    color: #000000;
}

.TitleText {
    color: $primary-color;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
}

.BtnClean {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    text-align: right;

    color: #000000;
}
