@import "../../styles/theme.scss";
@import "../../styles/breakpoints.scss";

.Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: 10px 0px 5px;
    max-width: 572px;

    @include breakpoint-tablet {
        width: 100%;
    }
}

.Text {
    font-family: $main-font;
    color: $main-text-color;
    font-size: 14px;
}

.Link {
    color: $primary-color;
    font-weight: 600;
    font-size: 14px;
    font-family: $main-font;
    text-decoration: underline;
    cursor: pointer;
}
