@import "../../styles/breakpoints.scss";
@import "../../styles/theme.scss";

@keyframes shine {
    to {
        background-position: 16px 16px, 100% 0, /* move highlight to right */ 100px 20px, 100px 50px;
    }
}

.Card {
    position: relative;
    width: 572px;
    height: auto;
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: $big-shadow;
    border: 1px solid white;
    transition: background 0.3s ease-in-out;
    margin: 8px 0;

    @include breakpoint-mobile {
        margin: 5px 0;
    }

    @include breakpoint-tablet {
        max-width: 572px;
        width: 100%;
    }

    &:hover {
        background: #f8f9fb;
    }

    p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
        margin: 0;
    }

    &:empty {
        border-radius: 6px;
        box-shadow: $big-shadow;
        max-width: 572px;
        height: 92px;
        /* change height to see repeat-y behavior */
        background-color: white;
        background-image: radial-gradient(circle 30px at 30px 30px, #dde2eb 99%, transparent 0),
            linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%),
            linear-gradient(#dde2eb 20px, transparent 0), linear-gradient(#dde2eb 20px, transparent 0);

        background-repeat: no-repeat;

        background-size: 100px 200px, /* circle */ 50px 200px, /* highlight */ 70% 200px, 30% 200px;

        background-position: 16px 16px, /* circle */ 0 0, /* highlight */ 100px 20px, 100px 50px;

        animation: shine 1s infinite;
    }
}

.Sticky {
    position: sticky;
    top: 24px;
    z-index: 1;

    @include breakpoint-tablet {
        top: 84px;
    }

    &::after {
        content: "";
        width: calc(100% + 20px);
        height: 90px;
        position: absolute;
        left: -10px;
        display: block;
        background: $background-color-light;
        top: -26px;
    }
}

.Disclosure {
    &::after {
        content: "";
        position: absolute;
        right: 20px;
        top: 40px;
        width: 14px;
        height: 14px;
        background: url("../../resources/ic-disclousure.svg") no-repeat;
    }
}

.Closed {
    &::before {
        content: "Cerrado";
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        font-family: $secondary-font;
        font-style: normal;
        text-transform: uppercase;
        width: auto;
        padding: 3px 10px;
        border-radius: 3px;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        color: $primary-color;
        background: $primary-color-opacity;
    }
}

.InfoDisclosure {
    &::after {
        content: "+info";
        position: absolute;
        right: 20px;
        top: 35px;
        font-family: $secondary-font;
        font-style: 600;
        font-size: 14px;
        color: #5a6f8b;
    }
}

.NoPadding {
    padding: 0;
    border: none;
}

.Clickable {
    cursor: pointer;
}
