@import "../../styles/breakpoints.scss";
@import "../../styles/theme.scss";

.Switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
    outline: none;

    @include breakpoint-tablet {
        width: 45px;
        height: 27px;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + span {
            background-color: $primary-color;

            &::before {
                transform: translateX(12px);

                @include breakpoint-tablet {
                    transform: translateX(18px);
                }
            }
        }
    }

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $background-color-medium;
        transition: 0.4s;
        border-radius: 17px;

        @include breakpoint-tablet {
            border-radius: 25px;
        }

        &::before {
            border-radius: 50%;
            position: absolute;
            content: "";
            height: 14px;
            width: 14px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            transition: 0.4s;

            @include breakpoint-tablet {
                height: 21px;
                width: 21px;
                left: 3px;
                bottom: 3px;
            }
        }
    }
}
