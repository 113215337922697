@import "../../styles/breakpoints.scss";
@import "../../styles/theme.scss";

.Container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;

    @include breakpoint-tablet {
        width: 100%;
        align-items: center;
    }
}

.Separator {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: auto;
    /* identical to box height */
    color: #5a6f8b;
    border-bottom: 1px solid #E2E5EA;
    padding-bottom: 5px;
    margin-top: 30px;
    margin-bottom: 15px;
}
