@import "../../styles/breakpoints.scss";

.Container {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
}

.Background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.Screen {
    position: fixed;
    right: 0;
    background: #fff;
    z-index: 102;
    width: 90%;
    height: 100%;
    max-width: 400px;
    overflow-y: scroll;
    box-shadow: 0px 5px 10px rgba(210, 219, 231, 0.5);

    @include breakpoint-mobile {
        width: 100%;
        max-width: 100%;
    }
}

.Content {
    padding: 30px;
    flex: 1 1 100%;
    margin-bottom: 70px;
}
