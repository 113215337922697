@import '../../styles/breakpoints.scss';


.Container {
  width: 100%;
  max-width: 480px;
  min-height: 100vh;
  position: relative;
  padding: 30px;
  margin: 0 auto;
  box-sizing: border-box;

  @include breakpoint-mobile {
    padding: 30px 10px;
  }
}

.Content {
  flex: 1 0 auto;
  padding: 10px;    

  @include breakpoint-mobile {
    background-color: burlywood;
  }

  @include breakpoint-tablet-only {
    background-color: yellowgreen;
  }
}
