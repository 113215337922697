@import "../../styles/breakpoints.scss";
@import "../../styles/theme.scss";

.Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: $background-color-dark;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;

  ul {
    list-style: none;
    margin: 10px;
  }

  @include breakpoint-mobile {
    flex-direction: column;
    padding: 20px;
  }
}

.Floating {
  position: fixed;
  bottom: 0;
}

.Logo {
  margin: 0;

  @include breakpoint-tablet {
    display: none;
  }
}

.Links {
  display: flex;
  flex-direction: row;
  padding: 0;

  @include breakpoint-mobile {
    flex-direction: column;
    align-items: center;
  }

  li {
    margin: 0 10px;
    padding: 0 10px;

    a {
      color: rgba($color: #ffffff, $alpha: 0.5);
      font-family: $secondary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-decoration: none;
    }

    @include breakpoint-mobile {
      margin: 4px;
    }
  }
}

.Social {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  img {
    margin: 0 10px;
    width: 24px;
    height: auto;
  }
}
